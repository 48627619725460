.image6Icon {
    position: relative;
    width: 85.33px;
    height: 48px;
    object-fit: cover;
  }
 
  .getStartedBy {
    position: relative;
    line-height: 28px;
  }
  .watchDemoVideo {
    color: var(--m3-sys-light-primary);
  }
  .dontKnowWhereContainer {
    position: relative;
    font-size: var(--m3-title-medium-size);
    line-height: 24px;
  }
  .getStartedByConnectingWithParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .icon {
    position: relative;
    width: 18px;
    height: 18px;
  }
  .labelText {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .button,
  .stateLayer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .stateLayer {
    padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
      var(--padding-base);
    gap: var(--gap-5xs);
  }
  .button {
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-primary);
    overflow: hidden;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-white);
  }
  .image6Parent {
    border-radius: var(--br-5xs);
    border: 1px dashed var(--m3-sys-light-outline);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 80px 120px;
    gap: var(--gap-5xl);
  }
  .stateLayer1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs);
  }
  .container {
    border-radius: var(--br-81xl);
    background-color: var(--m3-sys-light-primary-fixed-dim);
    overflow: hidden;
    flex-direction: row;
  }
  .container,
  .iconButton,
  .stateLayer2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconButton {
    width: 48px;
   
    height: 48px;
    flex-direction: column;
  }
  .stateLayer2 {
    flex-direction: row;
    padding: var(--padding-7xs) var(--padding-xs);
  }
  .inputChip {
    border-radius:8px;
    border: 1px solid #74777F;
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    cursor: pointer;
    padding: 6px;
    margin-left: 6px;
    font-size: 14px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  .inputChip,
  .inputChip2,
  .inputChipParent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .inputChip:hover,
.inputChip:active 
.selectedCategory{
  background-color: #ADC6FF;

}
  .inputChip2 {
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-primary-fixed-dim);
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    color: var(--m3-sys-light-on-secondary-container);
  }
  .inputChipParent {
    flex: 1;
    overflow-x: auto;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .container2,
  .iconButton2 {
    align-items: center;
    justify-content: center;
  }
  .container2 {
    border-radius: var(--br-81xl);
    background-color: var(--m3-sys-light-primary);
    overflow: hidden;
    display: flex;
    flex-direction: row;
  }
  .iconButton2 {
    width: 48px;
    height: 48px;
    display: none;
    flex-direction: column;
    padding: var(--padding-9xs);
    box-sizing: border-box;
  }
  .categories {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-2xs);
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-sys-light-on-surface-variant);
  }
  .resultsFound {
    position: relative;
    font-size: var(--m3-label-medium-size);
    line-height: 16px;
    font-weight: 500;
    color: var(--m3-sys-light-outline);
  }
  .labelText11,
  .powerSplCat1Parent {
    display: flex;
    justify-content: flex-start;
  }
  .powerSplCat1Parent {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-9xs);
  }
  .labelText11 {
    flex-direction: row;
    align-items: center;
  }
  .container3,
  .content {
    display: flex;
    justify-content: center;
  }
  .content {
    flex: 1;
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
  .container3 {
    border-radius: var(--br-81xl);
    overflow: hidden;
    flex-direction: row;
    align-items: center;
  }
  .stateLayer14,
  .textField1 {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .selectedCategory {
    background-color: #ADC6FF;
    font-weight: bold;
    border: none;
  }
  .stateLayer14 {
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0 var(--padding-9xs) var(--padding-base);
  }
  .textField1 {
    flex: 1;
    border-radius: var(--br-9xl);
    border: 1px solid var(--m3-sys-light-outline);
    flex-direction: column;
    justify-content: center;
  }
  .supportingText1 {
    flex: 1;
    position: relative;
    line-height: 16px;
  }
  .supportingText {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) var(--padding-base) 0;
    font-size: var(--m3-label-medium-size);
  }
  .content1,
  .textField {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .textField {
    border-radius: var(--br-9xl);
    width: 180px;
    flex-direction: column;
    justify-content: center;
  }
  .content1 {
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
  }
  .stTrailingIcon {
    width: 48px;
    height: 48px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .trailingElements {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .stateLayer16 {
    align-self: stretch;
    flex: 1;
    align-items: center;
    padding: var(--padding-9xs);
    gap: var(--gap-9xs);
  }
  .frameContainer,
  .searchBar,
  .stateLayer16,
  .textFieldParent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .searchBar {
    border-radius: var(--br-9xl);
    background-color: var(--m3-sys-light-surface-dim);
    width: 328px;
    height: 48px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
  }
  .frameContainer,
  .textFieldParent {
    align-items: flex-start;
  }
  .textFieldParent {
    gap: var(--gap-5xs);
    font-size: var(--m3-title-medium-size);
    color: var(--m3-sys-light-on-surface-variant);
  }
  .frameContainer {
    align-self: stretch;
    gap: var(--gap-5xl);
    text-align: left;
    color: var(--m3-black);
  }
  .border,
  .slNo {
    position: absolute;
  }
  .slNo {
    top: calc(50% - 8px);
    left: 16px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
    display: none;
  }
  .border {
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--gray-200);
    height: 1px;
  }
  .head {
    align-self: stretch;
    position: relative;
    border-radius: var(--br-5xs) 0 0 0;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
    font-family: var(--m3-title-medium);
  }
  .div {
    position: absolute;
    top: calc(50% - 10px);
    left: 16px;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  .avatars3dAvatar1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .dAvatars1 {
    position: absolute;
    top: calc(50% - 20px);
    right: 0;
    width: 40px;
    height: 40px;
  }
  .row {
    align-self: stretch;
    position: relative;
    background-color: var(--m3-white);
    height: 52px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .column {
    width: 48px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .viewInvoice {
    position: absolute;
    width: calc(100% - 57px);
    top: calc(50% - 8px);
    left: 24px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .head1 {
    align-self: stretch;
    position: relative;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    color: var(--gray-600);
    font-family: var(--m3-title-medium);
  }
  .powerSpoClosed,
  .rack1shelf1 {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .powerSpoClosed {
    align-self: stretch;
    line-height: 20px;
  }
  .rack1shelf1 {
    font-size: var(--m3-label-small-size);
    line-height: 16px;
    font-weight: 500;
    font-family: var(--m3-title-medium);
    color: var(--m3-sys-light-primary);
    display: flex;
    align-items: center;
    width: 142px;
  }
  .powerSpoClosedParent {
    position: absolute;
    top: calc(50% - 18px);
    left: 24px;
    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .powerSpoClosed1 {
    position: relative;
    line-height: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 142px;
  }
  .column1 {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .poNumber {
    position: absolute;
    width: calc(100% - 59.5px);
    top: calc(50% - 8px);
    left: 24px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .border10 {
    position: absolute;
    width: calc(100% + 0.5px);
    right: -0.5px;
    bottom: 0;
    left: 0;
    background-color: var(--gray-200);
    height: 1px;
  }
  .div4,
  .vendorCode {
    position: absolute;
    width: calc(100% - 54.5px);
    top: calc(50% - 10px);
    left: 24px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .vendorCode {
    width: calc(100% - 55.5px);
    top: calc(50% - 8px);
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
  }
  .div10 {
    width: calc(100% - 76.5px);
    top: calc(50% - 10px);
    line-height: 20px;
  }
  .div10,
  .susmanIndAgoSales,
  .vendor {
    position: absolute;
    left: 24px;
    display: flex;
    align-items: center;
  }
  .vendor {
    width: calc(100% - 96px);
    top: calc(50% - 8px);
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
  }
  .susmanIndAgoSales {
    width: calc(100% - 39px);
    top: calc(50% - 10px);
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .column4 {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .orderAmount {
    position: absolute;
    width: calc(100% - 47.5px);
    top: calc(50% - 8px);
    left: 24px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .div16 {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 49.08px);
    line-height: 20px;
  }
  .creationDate,
  .div21 {
    position: absolute;
    display: flex;
    align-items: center;
  }
  .div21 {
    top: calc(50% - 10px);
    left: calc(50% - 49.08px);
    line-height: 20px;
    width: 55px;
  }
  .creationDate {
    width: calc(100% - 49.5px);
    top: calc(50% - 8px);
    left: 24px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
  }
  .aug2022,
  .feb2023,
  .jul2022,
  .status {
    position: absolute;
    width: calc(100% - 57.5px);
    top: calc(50% - 11px);
    left: 24.33px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .feb2023,
  .jul2022,
  .status {
    width: calc(100% - 63.5px);
  }
  .feb2023,
  .status {
    width: calc(100% - 55.5px);
  }
  .status {
    width: calc(100% - 48px);
    top: calc(50% - 8px);
    left: 24px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
  }
  .head7 {
    align-self: stretch;
    position: relative;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
  }
  .inputChip10,
  .inputChip12 {
    position: absolute;
    top: calc(50% - 16px);
    left: 24px;
    border-radius: var(--br-5xs);
    background-color: var(--green-2);
    height: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .inputChip12 {
    background-color: var(--orange);
  }
  .column7,
  .head8 {
    font-family: var(--m3-title-medium);
  }
  .column7 {
    flex: 1;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    color: var(--neutral-white);
  }
  .head8 {
    align-self: stretch;
    position: relative;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
  }
  .sagechakraUicom {
    position: absolute;
    width: calc(100% - 48px);
    top: calc(50% - 10px);
    left: 24px;
    line-height: 20px;
    display: none;
    align-items: center;
  }
  .stateLayer24 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-5xl);
  }
  .button1,
  .markchakraUicom {
    position: absolute;
    left: 24px;
    align-items: center;
  }
  .button1 {
    top: calc(50% - 20px);
    border-radius: var(--br-81xl);
    background-color: var(--color-lavender-100);
    width: 108px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: var(--primary-blue);
    font-family: var(--m3-title-medium);
  }
  .markchakraUicom {
    width: calc(100% - 48px);
    top: calc(50% - 10px);
    line-height: 20px;
    color: inherit;
    display: none;
    text-decoration: none;
  }
  .column8 {
    flex: 1;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .table {
    align-self: stretch;
    border-radius: 12px;
    /* width: 1000px; */
    background-color: var(--m3-white);
    box-shadow: var(--m3-elevation-light-3);
    border: 1px solid var(--gray-200);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--gray-700);
    font-family: var(--text-sm-lineheight-5-font-normal);
    margin-top: 10px;
  }
  .frameGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .filters1 {
    position: relative;
    line-height: 28px;
    text-align: left;
  }
  .invoiceValueRange {
    position: relative;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    line-height: 20px;
    font-weight: 500;
    color: var(--m3-black);
  }
  .inputText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;
  }
  .labelText22 {
    position: relative;
    line-height: 16px;
  }
  .labelText21 {
    margin: 0 !important;
    position: absolute;
    top: -16px;
    left: -4px;
    background-color: var(--m3-sys-light-surface-bright);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--padding-9xs);
    z-index: 1;
    font-size: var(--m3-label-medium-size);
    color: var(--m3-sys-light-on-surface-variant);
  }
  .content2 {
    flex: 1;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }
  .stateLayer28 {
    align-self: stretch;
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0 var(--padding-5xs) var(--padding-base);
  }
  .supportingText3,
  .textField3 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .textField3 {
    align-self: stretch;
    flex: 1;
    border-radius: var(--br-9xs);
    border: 1px solid var(--m3-sys-light-outline);
    display: flex;
    flex-direction: column;
  }
  .supportingText3 {
    display: none;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-base) 0;
    font-size: var(--m3-label-medium-size);
    color: var(--m3-sys-light-on-surface-variant);
  }
  .supportingText3,
  .textField2,
  .to {
    align-self: stretch;
  }
  .textField2 {
    border-radius: var(--br-9xs);
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
  .to {
    position: relative;
    font-size: var(--m3-label-small-size);
    line-height: 16px;
    font-weight: 500;
  }
  .invoiceValueRangeParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .divider {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    height: 1px;
  }
  .horizontalfullWidth {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .invoiceDateRange {
    position: relative;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    line-height: 20px;
    font-weight: 500;
    color: var(--m3-black);
    text-align: center;
  }
  .calendarToday24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .textField6,
  .textField7 {
    align-self: stretch;
    border-radius: var(--br-9xs);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .textField7 {
    flex: 1;
    border: 1px solid var(--m3-sys-light-outline);
  }
  .textField6 {
    height: 48px;
  }
  .invoiceDateRangeParent,
  .textField8 {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .textField8 {
    border-radius: var(--br-9xs);
    height: 48px;
    display: flex;
    flex-direction: column;
  }
  .invoiceDateRangeParent {
    gap: var(--gap-5xs);
    text-align: left;
    color: var(--m3-sys-light-on-surface-variant);
  }
  .button5,
  .button6,
  .frameParent1,
  .invoiceDateRangeParent {
    display: flex;
    flex-direction: column;
  }
  .frameParent1 {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--m3-title-medium-size);
    color: var(--m3-sys-light-on-surface);
  }
  .button5,
  .button6 {
    flex: 1;
    border-radius: var(--br-9xs);
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
  .button5 {
    background-color: var(--m3-sys-light-primary);
  }
  .button6 {
    border: 1px solid var(--m3-sys-light-outline);
    color: var(--m3-sys-light-primary);
  }
  .buttonParent,
  .filters,
  .frameParent {
    display: flex;
    justify-content: space-between;
  }
  .buttonParent {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap-base);
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-white);
  }
  .filters,
  .frameParent {
    align-items: space-between;
  }
  .filters {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-surface-bright);
    box-shadow: var(--m3-elevation-light-1);
    width: 308px;
     margin-right: 90px;
    flex-direction: column;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-5xl);
    color: var(--m3-black);
  }
  .frameParent {
    position: relative;
    top: 84px;
    padding-left: 152px;
    width: 100%;
    flex-direction: row;
    gap: var(--gap-base);
  }
  .sidemenuChild {
    position: relative;
    width: 76px;
    height: 415px;
    display: none;
  }
  .department {
    flex: 1;
    position: relative;
    line-height: 24px;
  }
  .expandLess24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
  }
  .main {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--m3-headline-small-size);
  }
  .doticon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .addDepartment {
    position: relative;
    line-height: 24px;
  }
  .submenu,
  .submenu1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-5xs);
    gap: var(--gap-base);
  }
  .submenu {
    background-color: #438bef;
    color: var(--neutral-white);
  }
  .submenu1 {
    color: var(--primary-blue);
  }
  .menuitem,
  .sidemenu1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .menuitem {
    border-radius: var(--br-5xs);
    width: 377px;
    display: flex;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-base);
  }
  .sidemenu1 {
    align-self: stretch;
    display: none;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--dark-grey);
  }
  .menuitem1,
  .menuitem3,
  .uploadDocs {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadDocs {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    width: 88px;
  }
  .menuitem1,
  .menuitem3 {
    border-radius: 0 var(--br-5xs) var(--br-5xs) 0;
    width: 96px;
    flex-direction: column;
    padding: var(--padding-7xs) var(--padding-9xs);
    box-sizing: border-box;
    gap: var(--gap-5xs);
  }
  .menuitem3 {
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-dark-on-primary-container);
    color: var(--m3-ref-primary-primary50);
  }
  .sidemenuInner,
  .sidemenuParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sidemenuParent {
    gap: var(--gap-base);
  }
  .sidemenuInner {
    flex: 1;
  }
  .goToAdmin {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-secondary-container);
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--m3-sys-light-on-secondary-container);
  }
  .frameChild {
    position: relative;
    border-top: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    width: 241px;
    height: 1px;
  }
  .deepdelve {
    position: relative;
    letter-spacing: 0.46px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .poweredBy {
    font-weight: 300;
  }
  .zeonaiLabs {
    font-weight: 500;
  }
  .poweredByZeonaiContainer {
    position: relative;
    font-size: var(--m3-label-medium-size);
    letter-spacing: 0.46px;
    text-transform: capitalize;
    text-align: left;
  }
  .deepdelveParent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
  .frameWrapper {
    position: relative;
    width: 143px;
    height: 42px;
  }
  .lineParent,
  .sidemenu {
    flex-direction: column;
    justify-content: flex-start;
  }
  .lineParent {
    align-self: stretch;
    display: none;
    align-items: center;
    gap: var(--gap-5xl);
    font-size: var(--font-size-xl);
  }
  .sidemenu {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: var(--m3-white);
    border-right: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    height: 842px;
    display: flex;
    align-items: flex-start;
    padding: var(--padding-5xl) var(--padding-base) 40px;
    gap: 40px;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-sys-light-outline);
  }
  .image4Icon {
    position: relative;
    width: 41px;
    height: 40px;
    object-fit: cover;
  }
  .image4Wrapper {
    background-color: var(--m3-white);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px;
  }
  .searchInvoices {
    position: relative;
    line-height: 32px;
  }
  .syncYourDocuments {
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  .share24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
    opacity: 0.8;
  }
  .queryName {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--m3-title-medium-size);
    color: var(--dark-grey);
  }
  .tab {
    border-radius: var(--br-9980xl);
    background-color: var(--neutral-white);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-base);
  }
  .tab,
  .tab1,
  .tabs {
    flex-direction: row;
    align-items: center;
  }
  .tab1 {
    display: flex;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-base);
    color: var(--dark-grey);
  }
  .tabs {
    border-radius: 50px;
    background-color: #d6dbe1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08) inset;
    display: none;
    justify-content: flex-start;
    padding: var(--padding-9xs);
    text-align: center;
    font-size: var(--text-lg-lineheight-7-font-medium-size);
    color: var(--primary-blue);
  }
  .searchInvoicesParent {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .button7,
  .frameParent3 {
    flex-direction: row;
    align-items: center;
  }
  .button7 {
    border-radius: var(--br-81xl);
    background-color: var(--secondary-blue);
    overflow: hidden;
    display: none;
    justify-content: center;
    text-align: center;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-white);
  }
  .frameParent3 {
    align-self: stretch;
    width: 1101px;
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .frameItem {
    position: relative;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .hiAmrit {
    position: relative;
    letter-spacing: 0.46px;
    display: none;
  }
  .frameParent2,
  .notifications24pxParent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .notifications24pxParent {
    justify-content: flex-end;
    gap: var(--gap-5xl);
    color: var(--gray-900);
  }
  .frameParent2 {
    position: absolute;
    top: 0;
    left: calc(50% - 720px);
    background-color: var(--m3-white);
    box-shadow: var(--m3-elevation-light-1);
    width: 1440px;
    justify-content: space-between;
    padding: var(--padding-5xs) var(--padding-5xl);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--m3-headline-small-size);
    color: var(--secondary-blue);
  }
  
  .sarchInvoices {
    position: relative;
    background-color: var(--m3-sys-light-surface-container);
    width: 100%;
    height: auto;
    overflow-x: hidden; /* Hide horizontal overflow */
    overflow-y: hidden;  /* Enable vertical scrolling */
    text-align: center;
    font-size: var(--m3-title-large-size);
    color: var(--m3-sys-light-on-surface);
    font-family: var(--m3-title-medium);
    
  }
  