.doc_category_card_div{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.doc_category_card{
    background-color: white;
    width: 250px;
    height: 150px;
    margin: 10px;
    padding: 20px;
    border-radius: 20px;
    /* border: 1px solid gray; */
    box-shadow: 1px 1px 1px 0px gray;
}
.doc_category_card:hover{
    cursor: pointer;
    background-color: rgb(237, 234, 234);
}
/* .doc_category_card_icon{
    cursor: pointer;
} */
.image_div{
    display: flex;
    align-items: center;
    margin-top: 20px;
    /* justify-content: center; */

}
.image_div >span{
    margin-right: 25px;
    font-weight: bold;
}
.snackbardiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.doccategorytext:hover{
    cursor: pointer;
}
