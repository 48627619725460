.bgIcon {
  /* position: absolute; */
  /* top: calc(50% - 451px);
  left: calc(50% - 720px); */
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background-color: rgb(245, 250, 255);

  object-fit: cover;
  background: transparent;
}

.image4Icon {
  position: relative;
  width: 82px;
  height: 80px;
  object-fit: cover;
}
.image4Wrapper {
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px;
}
.frameChild {
  position: relative;
  width: 255.52px;
  height: 64px;
  display: none;
}
.login1,
.pleaseEnterYour {
  position: relative;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: var(--m-3-black, #000);
  text-align: center;
}

.loginParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.maskedIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.button {
  position: relative;
  line-height: 24px;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.assetcomponentbutton,
.assetcomponentbutton1,
.unstyledbutton {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.unstyledbutton {
  justify-content: flex-start;
  padding: 8px 22px;
}
.assetcomponentbutton,
.assetcomponentbutton1 {
  border-radius: 4px;
  border: 1px solid #1b1b1f;
  box-sizing: border-box;
  width: 360px;
  height: 52px;
  justify-content: center;
}
.assetcomponentbutton {
  flex-shrink: 0;
}
.assetcomponentbutton1 {
  position: absolute;
  top: 0;
  left: 0;
}
.asseticonvisibility {
  position: absolute;
  top: 14px;
  left: 322px;
  width: 24px;
  height: 24px;
}
.assetcomponentbuttonGroup {
  position: relative;
  width: 360px;
  height: 52px;
}
.forgotPassword,
.labelText {
  position: relative;
  line-height: 20px;
}
.forgotPassword {
  font-size: 14px;
  color: #001a41;
  text-decoration: none;
  font-weight: 400;
}
.labelText {
  font-weight: 500;
}
.button2,
.stateLayer {
  justify-content: center;
}
.stateLayer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px;
}
.button2 {
  align-self: stretch;
  border-radius: 4px;
  background-color: #005ac1;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}
.assetcomponentbuttonParent,
.button2,
.groupParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.assetcomponentbuttonParent {
  justify-content: center;
  gap: 24px;
  text-align: left;
  font-size: 16px;
  color: #575e71;
}
.groupParent {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(252, 251, 252, 0.6);
  backdrop-filter: blur(24px);
  justify-content: flex-start;
  padding: 32px 29.5px;
  gap: 32px;
}
.deepdelve {
  position: absolute;
  top: 0;
  left: 11px;
  font-size: 20px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  font-weight: 500;
  color: black;
}
.poweredBy {
  font-weight: 300;
  color: gray;
}
.zeonaiLabs {
  font-weight: 500;
  color: black;
}
.poweredByZeonaiContainer {
  position: absolute;
  top: 30px;
  left: 0;
  font-size: 12px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
}
.deepdelveParent {
  position: relative;
  width: 143px;
  height: 41px;
  font-size: 20px;
  color: #fcfbfc;
}
.frameParent {
  position: absolute;
  top: calc(50% - 290px);
  left: calc(50% - 209px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.login {
  position: relative;
  background-color: #eff1f3;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-size: 36px;
  color: #000;
  font-family: Roboto;
}
