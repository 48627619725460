/* .desktop_view_modal_show{
    @media (min-width:500px) {
        width: 550px;
    }
} */
@media (min-width: 500px) {
    .desktop_view_modal_show{
      width: 100%;
      
    }
  }