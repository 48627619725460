.image5Icon {
    position: absolute;
    bottom: 23px;
    left: 152px;
    border-radius: var(--br-5xs);
    width: 1264px;
    height: 795px;
    object-fit: cover;
  }
  .viewQuilt24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .goToAdmin {
    position: relative;
    line-height: 24px;
  }
  .person24pxParent,
  .profileDropdown,
  .viewQuilt24pxParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .person24pxParent,
  .profileDropdown {
    align-items: flex-start;
  }
  .profileDropdown {
    position: absolute;
    top: 66px;
    left: 1201px;
    border-radius: var(--br-9xs);
    background-color: var(--m3-white);
    box-shadow: var(--m3-elevation-dark-3);
    flex-direction: column;
    padding: var(--padding-base);
    gap: var(--gap-5xl);
  }
  .sidemenuChild {
    position: relative;
    width: 76px;
    height: 415px;
    display: none;
  }
  .department {
    flex: 1;
    position: relative;
    line-height: 24px;
  }
  .expandLess24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
  }
  .main {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--m3-headline-small-size);
  }
  .doticon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .submenu,
  .submenu1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-5xs);
    gap: var(--gap-base);
  }
  .submenu {
    background-color: #438bef;
    color: var(--neutral-white);
  }
  .submenu1 {
    color: var(--primary-blue);
  }
  .menuitem,
  .sidemenu1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .menuitem {
    border-radius: var(--br-5xs);
    width: 377px;
    display: flex;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-base);
  }
  .sidemenu1 {
    align-self: stretch;
    display: none;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--dark-grey);
  }
  .menuitem1,
  .menuitem2,
  .uploadDocs {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadDocs {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    width: 88px;
  }
  .menuitem1,
  .menuitem2 {
    width: 96px;
    flex-direction: column;
    padding: var(--padding-7xs) var(--padding-9xs);
    box-sizing: border-box;
    gap: var(--gap-5xs);
  }
  .menuitem1 {
    border-radius: 4px;
    background-color: var(--m3-sys-dark-on-primary-container);
    color: var(--m3-ref-primary-primary50);
  }
  .menuitem2 {
    border-radius: 0 var(--br-5xs) var(--br-5xs) 0;
  }
  .sidemenuInner,
  .sidemenuParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sidemenuParent {
    gap: var(--gap-base);
  }
  .sidemenuInner {
    flex: 1;
  }
  .icon {
    position: relative;
    width: 18px;
    height: 18px;
  }
  .labelText {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .goToAdmin1,
  .stateLayer {
    flex-direction: row;
    align-items: center;
  }
  .stateLayer {
    display: flex;
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
      var(--padding-base);
    gap: var(--gap-5xs);
  }
  .goToAdmin1 {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-secondary-container);
    overflow: hidden;
    display: none;
    justify-content: flex-start;
    color: var(--m3-sys-light-on-secondary-container);
  }
  .frameChild {
    position: relative;
    border-top: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    width: 241px;
    height: 1px;
  }
  .deepdelve {
    position: relative;
    letter-spacing: 0.46px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .poweredBy {
    font-weight: 300;
  }
  .zeonaiLabs {
    font-weight: 500;
  }
  .poweredByZeonaiContainer {
    position: relative;
    font-size: 12px;
    letter-spacing: 0.46px;
    text-transform: capitalize;
    text-align: left;
  }
  .deepdelveParent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
  .frameWrapper {
    position: relative;
    width: 143px;
    height: 42px;
  }
  .lineParent,
  .sidemenu {
    flex-direction: column;
    justify-content: flex-start;
  }
  .lineParent {
    align-self: stretch;
    display: none;
    align-items: center;
    gap: var(--gap-5xl);
    font-size: var(--font-size-xl);
  }
  .sidemenu {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: var(--m3-white);
    border-right: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    height: 842px;
    display: flex;
    align-items: flex-start;
    padding: var(--padding-5xl) var(--padding-base) 40px;
    gap: 40px;
    text-align: center;
    font-size: var(--m3-label-large-size);
    color: var(--m3-sys-light-outline);
  }
  .image4Icon {
    position: relative;
    width: 41px;
    height: 40px;
    object-fit: cover;
  }
  .image4Wrapper {
    background-color: var(--m3-white);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px;
  }
  .dashboard1 {
    position: relative;
    line-height: 32px;
  }
  .syncYourDocuments {
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  .share24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
    opacity: 0.8;
  }
  .queryName {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--m3-title-medium-size);
    color: var(--dark-grey);
  }
  .children {
    position: relative;
    line-height: 28px;
  }
  .tab {
    border-radius: var(--br-9980xl);
    background-color: var(--neutral-white);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-base);
  }
  .tab,
  .tab1,
  .tabs {
    flex-direction: row;
    align-items: center;
  }
  .tab1 {
    display: flex;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-base);
    color: var(--dark-grey);
  }
  .tabs {
    border-radius: 50px;
    background-color: #d6dbe1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08) inset;
    display: none;
    justify-content: flex-start;
    padding: var(--padding-9xs);
    text-align: center;
    font-size: var(--text-lg-lineheight-7-font-medium-size);
    color: var(--primary-blue);
  }
  .dashboardParent {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .button,
  .frameGroup {
    flex-direction: row;
    align-items: center;
  }
  .button {
    border-radius: var(--br-81xl);
    background-color: var(--secondary-blue);
    overflow: hidden;
    display: none;
    justify-content: center;
    text-align: center;
    font-size: var(--m3-label-large-size);
    color: var(--m3-white);
  }
  .frameGroup {
    align-self: stretch;
    width: 1101px;
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .frameItem {
    position: relative;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .hiAmrit {
    position: relative;
    letter-spacing: 0.46px;
    display: none;
  }
  .frameParent,
  .notifications24pxParent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .notifications24pxParent {
    justify-content: flex-end;
    gap: var(--gap-5xl);
    color: var(--gray-900);
  }
  .frameParent {
    position: absolute;
    top: 0;
    left: calc(50% - 720px);
    background-color: var(--m3-white);
    box-shadow: var(--m3-elevation-light-1);
    width: 1440px;
    justify-content: space-between;
    padding: var(--padding-5xs) var(--padding-5xl);
    box-sizing: border-box;
    font-size: var(--m3-headline-small-size);
    color: var(--secondary-blue);
  }
  .dashboard {
    position: relative;
    background-color: var(--m3-sys-light-surface-container);
    width: 100%;
    height: 902px;
    overflow: hidden;
    text-align: left;
    font-size: var(--m3-title-medium-size);
    color: var(--m3-sys-light-on-surface-variant);
    font-family: var(--m3-title-medium);
  }
  