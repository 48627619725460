.icon {
    position: relative;
    width: 18px;
    height: 18px;
  }
  .labelText {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .button,
  .stateLayer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .stateLayer {
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-base) var(--padding-3xs) 0;
    gap: var(--gap-5xs);
  }
  .button {
    align-self: stretch;
    border-radius: var(--br-81xl);
    overflow: hidden;
    justify-content: flex-start;
  }
  .repository {
    position: relative;
    line-height: 44px;
    display: none;
  }
  .documentTypeMcl {
    position: relative;
    line-height: 28px;
  }
  .share24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
    opacity: 0.8;
  }
  .queryName,
  .tab,
  .tab1 {
    display: flex;
    flex-direction: row;
  }
  .queryName {
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--m3-title-large-size);
    color: var(--dark-grey);
  }
  .tab,
  .tab1 {
    align-items: center;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-base);
  }
  .tab {
    border-radius: var(--br-9980xl);
    background-color: var(--neutral-white);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .tab1 {
    color: var(--dark-grey);
  }
  .repositoryParent,
  .tabs {
    justify-content: flex-start;
  }
  .tabs {
    border-radius: var(--br-31xl);
    background-color: var(--color-gainsboro-100);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08) inset;
    display: none;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-9xs);
    text-align: center;
    font-size: var(--text-lg-lineheight-7-font-medium-size);
    color: var(--primary-blue);
  }
  .repositoryParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xs);
  }
  .stateLayer1 {
    display: flex;
    justify-content: center;
    padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs)
      var(--padding-base);
    gap: var(--gap-5xs);
  }
  .button1,
  .frameContainer,
  .stateLayer1 {
    flex-direction: row;
    align-items: center;
  }
  .button1 {
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-primary);
    overflow: hidden;
    display: none;
    justify-content: center;
    text-align: center;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-white);
  }
  .frameContainer {
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
    gap: var(--gap-5xl);
  }
  .organisationHindalco {
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  .frameGroup,
  .queryName1 {
    display: flex;
    justify-content: flex-start;
  }
  .queryName1 {
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--m3-title-medium-size);
    color: var(--dark-grey);
  }
  .frameGroup {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  .button3,
  .frameParent {
    display: flex;
    flex-direction: row;
  }
  .button3 {
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-primary);
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-white);
  }
  .frameParent {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-end;
    gap: var(--gap-5xl);
    text-align: left;
    font-size: var(--m3-display-small-size);
    color: var(--secondary-blue);
  }
  .orderNo {
    position: absolute;
    width: calc(100% - 48px);
    top: calc(50% - 8px);
    left: 24px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .border {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--gray-200);
    height: 1px;
  }
  .div,
  .head {
    position: relative;
  }
  .head {
    align-self: stretch;
    border-radius: var(--br-5xs) 0 0 0;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
  }
  .div {
    line-height: 20px;
  }
  .wrapper {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 45px);
    border-radius: var(--br-9xs);
    border: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    width: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-11xs) var(--padding-5xs);
  }
  .row {
    align-self: stretch;
    flex: 1;
    position: relative;
    background-color: var(--m3-white);
    overflow: hidden;
  }
  .column,
  .head1 {
    align-self: stretch;
    font-family: var(--m3-title-medium);
  }
  .column {
    width: 124px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--m3-black);
  }
  .head1 {
    position: relative;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
  }
  .testMetaKey {
    position: absolute;
    width: calc(100% - 48px);
    top: calc(50% - 10px);
    left: 24px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .column1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .head3 {
    position: relative;
    background-color: var(--neutral-grey);
    width: 285px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
  }
  .inputChip,
  .stateLayer4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .stateLayer4 {
    padding: var(--padding-11xs) var(--padding-5xs);
  }
  .inputChip {
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-surface-container-highest);
    overflow: hidden;
  }
  .inputChipParent {
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200);
  }
  .inputChipParent,
  .inputChipParent1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-5xl);
    gap: var(--gap-5xs);
  }
  .column3,
  .head4,
  .inputChipParent1 {
    align-self: stretch;
  }
  .column3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    color: var(--m3-sys-light-on-secondary-container);
    font-family: var(--m3-title-medium);
  }
  .head4 {
    position: relative;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
  }
  .dataExtractionStatus {
    flex: 1;
    position: relative;
   
    font-size: 12px;
  }
  .toggleOn24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .dataExtractionStatusParent,
  .displayInColumnStatusParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
 
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .displayInColumnStatusParent {
    margin-top: -4px;
  }
  .frameParent1 {
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200);
    padding: var(--padding-3xs) var(--padding-5xl);
  }
  .column4,
  .frameParent1,
  .frameParent4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameParent4 {
    align-self: stretch;
    padding: var(--padding-3xs) var(--padding-5xl);
  }
  .column4 {
    flex: 1;
    font-size: var(--m3-body-small-size);
    color: var(--m3-black);
    font-family: var(--m3-title-medium);
  }
  .sagechakraUicom {
    position: absolute;
    width: calc(100% - 48px);
    top: calc(50% - 10px);
    left: 24px;
    line-height: 20px;
    display: none;
    align-items: center;
  }
  .button4,
  .stateLayer13 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stateLayer13 {
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-5xl);
  }
  .button4 {
    position: absolute;
    top: calc(50% - 20px);
    left: 24px;
    border-radius: var(--br-81xl);
    background-color: rgba(47, 128, 237, 0.1);
    border: 1px solid var(--primary-blue);
    box-sizing: border-box;
    width: 108px;
    overflow: hidden;
    flex-direction: column;
    text-align: center;
    color: var(--primary-blue);
    font-family: var(--m3-title-medium);
  }
  .row12 {
    align-self: stretch;
    position: relative;
    background-color: var(--m3-white);
    height: 52px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .markchakraUicom {
    position: absolute;
    width: calc(100% - 48px);
    top: calc(50% - 10px);
    left: 24px;
    line-height: 20px;
    color: inherit;
    display: none;
    align-items: center;
    text-decoration: none;
  }
  .button5,
  .column5 {
    flex-direction: column;
  }
  .button5 {
    position: absolute;
    top: calc(50% - 20px);
    left: 24px;
    border-radius: var(--br-81xl);
    background-color: var(--color-dodgerblue-100);
    border: 1px solid var(--primary-blue);
    box-sizing: border-box;
    width: 108px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--primary-blue);
    font-family: var(--m3-title-medium);
  }
  .column5 {
    flex: 1;
    display: none;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .head6 {
    align-self: stretch;
    position: relative;
    border-radius: 0 var(--br-5xs) 0 0;
    background-color: var(--neutral-grey);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--m3-title-medium-size);
    color: var(--gray-600);
    font-family: var(--m3-title-medium);
  }
  .button8,
  .stateLayer17 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stateLayer17 {
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-xs);
  }
  .button8 {
    position: absolute;
    top: calc(50% - 20px);
    left: 24px;
    border-radius: var(--br-81xl);
    overflow: hidden;
    flex-direction: column;
    text-align: center;
    color: var(--m3-sys-light-primary);
    font-family: var(--m3-title-medium);
  }
  .deleteOutline24pxIcon {
    position: absolute;
    top: 14px;
    left: 24px;
    width: 24px;
    height: 24px;
    display: none;
  }
  .table {
    align-self: stretch;
    border-radius: 12px;
    background-color: var(--m3-white);
    box-shadow: var(--m3-elevation-light-3);
    border: 1px solid var(--gray-200);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    color: var(--gray-700);
    font-family: var(--text-sm-lineheight-5-font-normal);
  }
  .button12,
  .buttonParent {
    display: flex;
    flex-direction: column;
  }
  .button12 {
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-primary);
    overflow: hidden;
    align-items: center;
    justify-content: center;
    color: var(--m3-white);
  }
  .buttonParent {
    position: absolute;
    top: 84px;
    left: 152px;
    width: 1264px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .sidemenuChild {
    position: relative;
    width: 76px;
    height: 415px;
    display: none;
  }
  .department {
    flex: 1;
    position: relative;
    line-height: 24px;
  }
  .expandLess24pxIcon {
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
  }
  .main {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--m3-headline-small-size);
  }
  .doticon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .addDepartment {
    position: relative;
    line-height: 24px;
  }
  .submenu,
  .submenu1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-5xs);
    gap: var(--gap-base);
  }
  .submenu {
    background-color: #438bef;
    color: var(--neutral-white);
  }
  .submenu1 {
    color: var(--primary-blue);
  }
  .menuitem,
  .sidemenu1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .menuitem {
    border-radius: var(--br-5xs);
    width: 377px;
    display: flex;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-base);
  }
  .sidemenu1 {
    align-self: stretch;
    display: none;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--dark-grey);
  }
  .menuitem1,
  .menuitem4,
  .uploadDocs {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadDocs {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    width: 88px;
  }
  .menuitem1,
  .menuitem4 {
    border-radius: 0 var(--br-5xs) var(--br-5xs) 0;
    width: 96px;
    flex-direction: column;
    padding: var(--padding-7xs) var(--padding-9xs);
    box-sizing: border-box;
    gap: var(--gap-5xs);
  }
  .menuitem4 {
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-dark-on-primary-container);
    color: var(--m3-ref-primary-primary50);
  }
  .sidemenuInner,
  .sidemenuParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sidemenuParent {
    gap: var(--gap-base);
  }
  .sidemenuInner {
    flex: 1;
  }
  .goToAdmin {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--m3-sys-light-secondary-container);
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--m3-sys-light-on-secondary-container);
  }
  .frameChild {
    position: relative;
    border-top: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    width: 241px;
    height: 1px;
  }
  .deepdelve {
    position: relative;
    letter-spacing: 0.46px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .poweredBy {
    font-weight: 300;
  }
  .zeonaiLabs {
    font-weight: 500;
  }
  .poweredByZeonaiContainer {
    position: relative;
    font-size: var(--m3-body-small-size);
    letter-spacing: 0.46px;
    text-transform: capitalize;
    text-align: left;
  }
  .deepdelveParent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
  .frameWrapper {
    position: relative;
    width: 143px;
    height: 42px;
  }
  .lineParent,
  .sidemenu {
    flex-direction: column;
    justify-content: flex-start;
  }
  .lineParent {
    align-self: stretch;
    display: none;
    align-items: center;
    gap: var(--gap-5xl);
    font-size: var(--font-size-xl);
  }
  .sidemenu {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: var(--m3-white);
    border-right: 1px solid var(--m3-sys-light-outline-variant);
    box-sizing: border-box;
    height: 842px;
    display: flex;
    align-items: flex-start;
    padding: var(--padding-5xl) var(--padding-base) 40px;
    gap: 40px;
    color: var(--m3-sys-light-outline);
  }
  .image4Icon {
    position: relative;
    width: 32.8px;
    height: 32px;
    object-fit: cover;
  }
  .image4Wrapper {
    background-color: var(--m3-white);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs);
  }
  .adminConsole {
    position: relative;
    line-height: 16px;
    font-weight: 500;
  }
  .frameParent6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--m3-label-small-size);
  }
  .documentEntity {
    position: relative;
    line-height: 32px;
  }
  .queryName2 {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--m3-title-medium-size);
    color: var(--dark-grey);
  }
  .documentEntityParent {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .button13,
  .frameParent7 {
    flex-direction: row;
    align-items: center;
  }
  .button13 {
    border-radius: var(--br-81xl);
    background-color: var(--secondary-blue);
    overflow: hidden;
    display: none;
    justify-content: center;
    text-align: center;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-white);
  }
  .frameParent7 {
    align-self: stretch;
    width: 1131px;
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .frameItem {
    position: relative;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .hiAmrit {
    position: relative;
    letter-spacing: 0.46px;
    display: none;
  }
  .frameParent5,
  .notifications24pxParent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .notifications24pxParent {
    justify-content: flex-end;
    gap: var(--gap-5xl);
    color: var(--gray-900);
  }
  .frameParent5 {
    position: absolute;
    top: 0;
    left: calc(50% - 720px);
    background-color: var(--m3-white);
    box-shadow: var(--m3-elevation-light-1);
    width: 1440px;
    height: 60px;
    justify-content: space-between;
    padding: var(--padding-5xs) var(--padding-5xl);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--m3-headline-small-size);
    color: var(--secondary-blue);
  }
  .adminDocEntity {
    position: relative;
    background-color: var(--m3-sys-light-surface-container);
    width: 100%;
    height: 902px;
    overflow: hidden;
    text-align: center;
    font-size: var(--text-sm-lineheight-5-font-normal-size);
    color: var(--m3-sys-light-primary);
    font-family: var(--m3-title-medium);
  }
  
  .key_content_options{
    background-color: rgba(0, 0, 0, 0.5);
  }